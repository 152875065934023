<template>
  <validation-provider
    v-slot="{ errors, validate: _validate }"
    :events="events"
    :name="fieldName"
    :rules="extendedRules"
    tag="div"
  >
    <v-text-field
      ref="textField"
      v-model="modelValue"
      v-bind="$attrs"
      :data-anonymize="anonymize"
      :error-messages="errors"
      :name="fieldName"
      :required="required"
      :suffix="suffix"
      persistent-hint
      v-on="$listeners"
      @blur="$eventLogger.blurEvent"
      @click="$eventLogger.clickEvent"
      @change="onChange(_validate)"
      @focus="$eventLogger.focusEvent"
      @keydown="$eventLogger.keyEvent"
      @keyup="$eventLogger.keyEvent"
    >
      <template #append-outer>
        <slot name="append-outer"/>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwTextField',

  components: {
    'validation-provider': ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    anonymize: {
      type: Boolean,
      default: false,
    },

    events: {
      type: [String, Array],
      default: 'change',
    },

    extendRules: {
      type: Boolean,
      default: true,
    },

    rules: {
      type: String,
      default: '',
    },

    // must be included in props
    value: {
      type: null,
      default: null,
      required: true,
    },

    currencyInput: {
      type: Boolean,
      default: false,
    },
  },

  data: vm => ({
    listeners: {
      change: vm.$eventLogger.changeEvent,
      paste: vm.$eventLogger.pasteEvent,
    },
    modelValue: '',
  }),

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
      initialData: 'application/getInitialData',
      locale: 'application/locale',
    }),

    required() {
      return this.rules.split('|').includes('required');
    },

    uppercase() {
      return Object.keys(this.$attrs).includes('uppercase');
    },

    fieldName() {
      return this.$attrs.id;
    },

    hasApiError() {
      return this.apiErrors.includes(this.fieldName);
    },

    preventSetData() {
      return Object.keys(this.$attrs).includes('prevent-set-data');
    },

    extendedRules() {
      if (!this.extendRules) return this.rules;

      const delimiter = !this.rules ? '' : '|';
      return `${this.rules}${delimiter}error:${this.hasApiError}`;
    },

    initialValue() {
      const v = this.initialData[this.fieldName] || this.value;

      if (this.currencyInput && v) return v / 100;

      return v;
    },

    inputElement() {
      return this.$el.querySelector(`#${this.fieldName}`);
    },

    suffix() {
      if (!this.currencyInput) return '';

      const [, symbol] = this.$filters.currency(0, {
        locale: this.locale,
      }).split(' ');

      return symbol;
    },
  },

  watch: {
    // Handles internal model changes.
    modelValue(newVal) {
      let value = newVal;

      if (this.currencyInput && newVal) value *= 100;

      this.$emit('input', value);
    },

    // Handles external model changes.
    value(newVal) {
      let v = newVal;

      if (this.uppercase) {
        this.modelValue = v.toUpperCase();
        return;
      }
      if (this.currencyInput && v) v /= 100;

      this.modelValue = v;
    },
  },

  created() {
    this.setInitialValue();
  },

  mounted() {
    this.$eventLogger.addEventListeners(this.inputElement, this.listeners);
  },

  beforeDestroy() {
    this.$eventLogger.removeEventListeners(this.inputElement, this.listeners);
  },

  methods: {
    ...mapActions({
      setData: 'application/setData',
      removeApiError: 'application/removeApiError',
    }),

    async onChange(validate) {
      this.removeApiError(this.fieldName);

      await this.$nextTick();
      const { valid } = await this.validate(validate);

      if (!valid || this.preventSetData) return;

      await this.submit();

      // Validation after HTTP request is required so that
      // potential API errors are rendered in UI
      await this.validate(validate);
    },

    setInitialValue() {
      this.modelValue = this.initialValue;
    },

    async submit() {
      const params = {
        [this.fieldName]: this.value,
      };

      this.setData(params);
    },

    async validate(validate) {
      const response = await validate();

      this.$emit('validate', response);

      return response;
    },
  },
};
</script>
